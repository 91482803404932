

const PhotoPortfolio = () => {
    return (
        <h1>
            This is still in development, come back later !
        </h1>
    )
}

export default PhotoPortfolio