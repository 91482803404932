import en from './en';
import fr from './fr';


const lang = {
  'en': en,
  'fr': fr,
};

export default lang;
